import Button from "@material-ui/core/Button";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import globalColors from '../theme/colors';
import { green } from '@material-ui/core/colors';
import Grid from '@material-ui/core/Grid';
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Radio from '@material-ui/core/Radio';
import React from 'react';
import SortIcon from '@material-ui/icons/Sort';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { getBatchRoi, getPendingRows } from "../actions/global";
import { useDispatch, useSelector } from "react-redux";
import { setBatchId } from "../actions/global";

const useStyles = makeStyles((theme) => ({
    tableData: {
        color: globalColors.textColor1,
        fontSize: "14px",
        fontWeight: "400",
        letterSpacing: "0.15px"
    },
    tableHeader: {
        color: globalColors.textColor1,
        fontSize: "14px",
        fontWeight: "400",
        letterSpacing: "0.15px"
    }
}));

const AntTabs = withStyles({
    indicator: {
    //   backgroundColor: "#1A73E8"
    display: "none"
    }
  })(Tabs);


const AntTab = withStyles((theme) => ({
root: {
    // border: '1px solid #2B78C2',
    textTransform: "none",
    "&:hover": {
    color: "#1A73E8",
    opacity: 1
    },
    "&$selected": {
    backgroundColor: "#E5EDFF",
    color: "#1A73E8",
    fontWeight: theme.typography.fontWeightMedium
    },
    "&:focus": {
    color: "#1A73E8"
    },
    
},
selected: {}
}))((props) => <Tab disableRipple {...props} />);

const CustomRadio = withStyles({
    root: {
      color: globalColors.brandColor1,
      '&$checked': {
        color: globalColors.brandColor1,
      },
    },
    checked: {},
  })((props) => <Radio color="default" {...props} />);
  

export default function PendingTask ({ output, setState }) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [buttonPressed, setButtonPressed] = React.useState(false)
    const [pendingRows, setPendingRows] = React.useState([])
    const [completedRows, setCompletedRows] = React.useState([])
    const [pendingRowsToShow, setPendingRowsToShow] = React.useState([])
    const [completedRowsToShow, setCompletedRowsToShow] = React.useState([])
    const [value, setValue] = React.useState(0)
    const [sortOrder, setSortOrder] = React.useState(0)
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [pageNumber, setPageNumber] = React.useState(1)
    const [totalEntries, setTotalEntries] = React.useState(0)

    // const [menuOpen, setMenuOpen] = React.useState(Boolean(anchorEl))
    const menuOpen = Boolean(anchorEl)
    const pageSize = 10
    

    
    const globalBatchId = useSelector(state => state.globalActionsReducer.batch_id)?.batch_id;

    React.useEffect(() => {
        dispatch(getPendingRows()).then(res => {
            setPendingRows(res?.pending_rows ? res?.pending_rows: [])
            setCompletedRows(res?.completed_rows ? res?.completed_rows: [])
        })
    }, [output])

    React.useEffect(() => {
        if (globalBatchId !== undefined && buttonPressed) {
            dispatch(getBatchRoi(globalBatchId)).then(() => { setState('m') })
            setButtonPressed(false)
        }
      }, [globalBatchId])
    

    React.useEffect(() => {
        pendingRows.sort((input1, input2) => {
            if (sortOrder == 0) {
                if (input1.updated_at < input2.updated_at) 
                    return -1
                return 1
            } 
            else if (sortOrder == 1) {
                if ( input1.updated_at < input2.updated_at )
                    return 1
                return -1
            }
        })

        completedRows.sort((input1, input2) => {
            if (sortOrder == 0) {
                if (input1.updated_at < input2.updated_at) 
                    return -1
                return 1
            } 
            else if (sortOrder == 1) {
                if ( input1.updated_at < input2.updated_at )
                    return 1
                return -1
            }
        })
        setPendingRows([...pendingRows])
        setCompletedRows([...completedRows])
    }, [sortOrder])


    React.useEffect(() => {
        if(value == 0){
            let totalCount = pendingRows?.length
            setTotalEntries(totalCount)
            let startIndex = pageSize * (pageNumber - 1)
            let endIndex = startIndex + pageSize
            setPendingRowsToShow([...pendingRows.slice(startIndex, endIndex)])

        }
        else if(value == 1){
            let totalCount = completedRows?.length
            setTotalEntries(totalCount)
            let startIndex = pageSize * (pageNumber - 1)
            let endIndex = startIndex + pageSize
            setCompletedRowsToShow([...completedRows.slice(startIndex, endIndex)])

        }
    }, [value, pendingRows, completedRows, pageNumber])


    function resumeBatch (batchId) {
        setButtonPressed(true)
        dispatch(setBatchId({ batch_id: String(batchId) }))
      }
    
      function handleChange(event, newValue) {
        setValue(newValue);
      };
      function createTabHeader(header){
        return (
          <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", fontSize: "16px", alignContent: "flex-start", fontWeight: "500" }}>
            <div>{header}</div>
          </div>
        )
      }

      function handleClick(event) {
        setAnchorEl(event.currentTarget);
      };

      function handleClose(event) {
        setAnchorEl(null);
        setSortOrder(event.target.value)
      };

      function goNext(){
          let maxPage = totalEntries === 0 ? 0 : Math.round((totalEntries - 1)/pageSize)
          if(pageNumber < maxPage){
              setPageNumber(pageNumber + 1)
          }
      }

    function goBack(){
        if(pageNumber > 1){
            setPageNumber(pageNumber - 1)
        }
    }


    return (
        <>
            <Grid item xs={12} md={12} lg={12} style={{display: "flex", justifyContent: "space-between", marginBottom: "10px"}}>
                <Typography style={{fontWeight: "500", fontSize: "20px", color: globalColors.textColor2}}>
                    Task List
                </Typography>
                <div style={{display: "flex"}}>
                    <Button onClick={handleClick}>
                        <Typography style={{fontWeight: "400", fontSize: "16px", color: globalColors.textColor2}}>
                            Sort By
                        </Typography>
                        <SortIcon style={{color: globalColors.textColor2, marginLeft: "10px"}}/>
                    </Button>
                    <Menu
                        id="sort-menu"
                        anchorEl={anchorEl}
                        open={menuOpen}
                        onClose={handleClose}
                        anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                        transformOrigin={{vertical: 'top', horizontal: 'left'}}    
                        MenuListProps={{
                            "aria-labelledby": "basic-button",
                        }}
                        >
                        <MenuItem onClick={handleClose} value={0}>
                            Oldest to Newest 
                            <CustomRadio checked={sortOrder == 0} style={{marginLeft: "30px"}}/> 
                        </MenuItem>
                        <MenuItem onClick={handleClose} value={1}>
                            Newest to Oldest
                            <CustomRadio checked={sortOrder == 1} style={{marginLeft: "30px"}}/> 
                        </MenuItem>
                    </Menu>
                </div>
            </Grid>
            <Grid item xs={12} md={12} lg={12} style={{ display: "flex", backgroundColor: "#FCFCFC", padding: "5px", border: "1px solid #DADCE0", flexDirection: "column", marginBottom: "20px"}}>
                <div style={{display: "flex", justifyContent: "space-between"}}>
                
                </div>
                <div>
                <AntTabs value={value} indicatorColor="primary" textColor="primary" onChange={handleChange} centered >
                    <AntTab label={createTabHeader("Pending Task")} style={{ borderBottomLeftRadius: "8px", borderTopLeftRadius: "8px", width: "50%" }} />
                    <AntTab label={createTabHeader("Complete")} style={{ borderBottomRightRadius: "8px", borderTopRightRadius: "8px", width: "50%" }} />
                </AntTabs>
                </div>
                <div style={{maxHeight: "300px", overflow: "auto" }}>
                { value === 0 ?
                pendingRowsToShow?.length > 0 &&
                <Table padding="none" aria-label="simple table" stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" height="50px" width="20%" className={classes.tableHeader} style={{ borderRight: "1px solid #DADCE0", minHeight: "50px", fontWeight: 500}}>Nikshay ID Entered</TableCell>
                            <TableCell align="center" height="50px" className={classes.tableHeader} style={{ borderRight: "1px solid #DADCE0", minHeight: "50px", fontWeight: 500}}>Status</TableCell>
                            <TableCell align="center" height="50px" className={classes.tableHeader} style={{ borderRight: "1px solid #DADCE0", minHeight: "50px", fontWeight: 500 }}>Date</TableCell>
                            <TableCell align="center" height="50px" className={classes.tableHeader} style={{minHeight: "50px", fontWeight: 500}}>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {pendingRowsToShow.map((row, i) => {
                        return (
                            <TableRow key={i}>
                            <TableCell align="center" style={{ borderRight: "1px solid #DADCE0", borderBottom: "1px solid #DADCE0", minHeight: "50px" }}>
                            <Typography className={classes.tableData}>{row.lab_sample_id === "id not present" ? "ID Not Present": row.lab_sample_id}</Typography>
                            </TableCell>
                            <TableCell align="center" style={{ borderRight: "1px solid #DADCE0", borderBottom: "1px solid #DADCE0", minHeight: "50px" }}>
                            <Typography className={classes.tableData}>{row.status === "ID not present" ? "Blank": row.status}</Typography>
                            </TableCell>
                            <TableCell align="center" style={{ borderRight: "1px solid #DADCE0", borderBottom: "1px solid #DADCE0", minHeight: "50px" }}>
                            <Typography className={classes.tableData} style={{color: globalColors.textColor3}}>{row.updated_at}</Typography>

                            </TableCell>
                            <TableCell align="center" style={{ borderBottom: "1px solid #DADCE0", minHeight: "50px", color: "#DADCE0", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                            <Button onClick={() => {
                                resumeBatch(row.batch_id)
                            }}>
                            <Typography className={classes.tableData} style={{color: globalColors.brandColor2}}>Update</Typography>
                            <ChevronRightIcon style={{ color: globalColors.brandColor2, cursor: "pointer" }}/>
                            </Button>

                            </TableCell>
                            
                            </TableRow>
                    )
                    })}
                    </TableBody>
                </Table>
                :
                completedRowsToShow?.length > 0 &&
                <Table padding="none" aria-label="simple table" stickyHeader>
                    <TableHead >
                        <TableRow>
                            <TableCell align="center" height="50px" width="30%" className={classes.tableHeader} style={{ borderRight: "1px solid #DADCE0", minHeight: "50px", fontWeight: 500}}>Nikshay ID Entered</TableCell>
                            <TableCell align="center" height="50px" className={classes.tableHeader} style={{ borderRight: "1px solid #DADCE0", minHeight: "50px", fontWeight: 500}}>ID Updated to</TableCell>
                            <TableCell align="center" height="50px" className={classes.tableHeader} style={{ borderRight: "1px solid #DADCE0", minHeight: "50px", fontWeight: 500 }}>Updated on</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {completedRowsToShow.map((row, i) => {
                        return (
                            <TableRow key={i}>
                            <TableCell height="50px" align="center" style={{ borderRight: "1px solid #DADCE0", borderBottom: "1px solid #DADCE0", minHeight: "50px" }}>
                            <Typography className={classes.tableData}>{row.lab_sample_id === "id not present" ? "ID Not Present": row.lab_sample_id}</Typography>
                            </TableCell>
                            <TableCell height="50px" align="center" style={{ borderRight: "1px solid #DADCE0", borderBottom: "1px solid #DADCE0", minHeight: "50px" }}>
                            <Typography className={classes.tableData}>{row.updated_id}</Typography>
                            </TableCell>
                            <TableCell height="50px" align="center" style={{ borderRight: "1px solid #DADCE0", borderBottom: "1px solid #DADCE0", minHeight: "50px" }}>
                            <Typography className={classes.tableData} style={{color: globalColors.textColor3}}>{row.updated_at}</Typography>

                            </TableCell>
                            
                            </TableRow>
                    )
                    })}
                    </TableBody>
                </Table>

                }
                </div>
            </Grid>
            {
            ((value == 0 && pendingRows?.length > 0) || (value == 1 && completedRows?.length > 0)) &&
            <Grid item xs={12} md={12} lg={12} style={{display: "flex", justifyContent: "space-between", marginBottom: "100px"}}>
                <Typography style={{fontWeight: "500", fontSize: "18px", color: globalColors.textColor3, marginLeft: "10px"}}>
                    {pageSize} of {totalEntries}
                </Typography>
                <div style={{display: "flex", marginRight: "10px"}}>
                        <Button onClick={goBack}>
                            <ChevronLeftIcon style={{ color: globalColors.brandColor1, cursor: "pointer" }}/>
                        </Button>
                        <div style={{display: "flex", alignItems: "center"}}>
                        <Typography style={{fontWeight: "500", fontSize: "18px", color: globalColors.textColor3}}>
                            {pageNumber}/{totalEntries === 0 ? 0 : Math.round((totalEntries - 1)/pageSize)}
                        </Typography>
                        <Button onClick={goNext}>
                            <ChevronRightIcon style={{ color: globalColors.brandColor1, cursor: "pointer" }}/>
                        </Button>
                        </div>
                </div>
            </Grid>
            }
            
        </>
    )
}
